var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"fd-app-mode-ead-header"},[_c('div',{staticClass:"fd-app-mode-ead-header-bg",style:({
        backgroundImage:
          'url(' + _vm.$store.state.fastPlataforma.banner_plataforma + ')',
        backgroundColor: '#000',
      })}),_c('div',{staticClass:"fd-app-mode-ead-header-overlay"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-8"},[_c('h1',{style:({
                color: _vm.$store.state.fastPersonalizacao.titulo_banner_color
                  ? _vm.$store.state.fastPersonalizacao.titulo_banner_color
                  : '#fff',
                textShadow: '1px 1px #000',
              })},[_vm._v(" "+_vm._s(_vm.$store.state.fastPersonalizacao.titulo_banner ? _vm.$store.state.fastPersonalizacao.titulo_banner : "")+" ")])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('mural-avisos')],1)])])])]),_c('div',{staticClass:"fd-app-wrap"},[_c('div',{staticClass:"container"},[_c('nav',[_c('ul',{staticClass:"fd-app-breadcrumb fast-plataforma-iuea-hide"},[_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"/minhas-plataformas"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/minhas-plataformas')}}},[_vm._v("Minhas Plataformas")])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma)}}},[_vm._v("Plataforma "+_vm._s(_vm.$store.state.fastPlataforma.nome_plataforma))])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/aluno'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
                  '/plataforma/' + _vm.$route.params.id_plataforma + '/aluno'
                )}}},[_vm._v("Modo aluno")])]),_vm._m(0)]),_c('ul',{staticClass:"fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea"},[_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":'/plataforma/' + _vm.$route.params.id_plataforma + '/aluno'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
                  '/plataforma/' + _vm.$route.params.id_plataforma + '/aluno'
                )}}},[_vm._v("Menu principal")])]),_vm._m(1)])])]),_c('div',{staticClass:"fd-mode-ead-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('transition',{attrs:{"name":"slide-fade"}},[(!_vm.isMobile())?_c(_vm.HomeInternoAlunoMenuLateral,{tag:"component"}):_vm._e()],1),_c('div',{class:_vm.isMobile()
                ? 'col-sm-12 col-md-12 col-lg-12'
                : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'},[_c('div',{staticClass:"fd-mode-ead-content mt-0 fast-iuea-ml-20"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('section',{staticClass:"fd-app-welcome text-left fast-plataforma-iuea-hide"},[_vm._m(2),_c('div',{staticClass:"fd-welcome-form-effect fast-plataforma-iuea-hide"},[_c('img',{staticClass:"mt-2",attrs:{"src":require('@/assets/images/form-effect-01.png')}})])]),_c('section',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"card shadow mb-4"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 table-responsive mt-2"},[_c('table',{staticClass:"table table-sm"},[_vm._m(4),(_vm.fastTutorialTotal.length)?_c('tbody',_vm._l((_vm.fastTutorialTotal),function(Tutorial){return _c('tr',{key:Tutorial.id_tutorial},[_c('td',{staticClass:"align-middle text-center"},[_vm._v(" "+_vm._s(Tutorial.descricao)+" ")]),_c('td',{staticClass:"text-center"},[(Tutorial.tipo == 'A')?_c('div',[_c('a',{staticClass:"btn btn-primary btn-sm mt-2 ml-2",attrs:{"href":_vm.ajustaLinkApiAntiga(Tutorial.url),"target":"_blank","download":""}},[_vm._m(5,true)])]):(
                                                    Tutorial.tipo == 'V'
                                                  )?_c('div',[_c('button',{staticClass:"btn btn-primary btn-sm mt-2 ml-2",on:{"click":function($event){return _vm.visualizarAtividade(
                                                        Tutorial
                                                      )}}},[_vm._m(6,true)])]):_vm._e()])])}),0):_c('tbody',[_vm._m(7)])])])])])])])])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mt-4"},[_c('a',{staticClass:"btn btn-red-hollow fast-iuea-ml-20",attrs:{"href":'/plataforma/' +
                              _vm.$route.params.id_plataforma +
                              '/aluno'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
                                '/plataforma/' +
                                  _vm.$route.params.id_plataforma +
                                  '/aluno'
                              )}}},[_c('b-icon-arrow-return-left'),_vm._v(" Voltar ")],1)])])])])])])])])],1)])])]),_c('modal',{attrs:{"name":"modalVisualizarAtividade","scrollable":true,"height":"auto","shift-y":0.1,"focus-trap":true,"adaptive":true}},[_c('div',{staticClass:"row p-4"},[_c('div',{staticClass:"col-6 pl-4 pr-4"},[_c('h4',[_vm._v("Tutorial - "+_vm._s(_vm.fastTutorialVisualiza.descricao))])]),_c('div',{staticClass:"col-6 pl-4 pr-4 text-right"},[_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.hideModal('modalVisualizarAtividade')}}},[_c('b-icon-arrow-return-left')],1)]),_c('div',{staticClass:"col-12 p-4"},[_c('div',{staticClass:"row"},[(_vm.fastTutorialVisualiza.tipo == 'V')?_c('div',{staticClass:"col-12"},[_c('iframe',{attrs:{"src":_vm.ajustaLinkApiAntiga(_vm.fastTutorialVisualiza.url),"width":"100%","height":"315","frameborder":"0"}})]):_c('div',{staticClass:"col-12"},[_c('audio',{staticClass:"d-block m-auto",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.ajustaLinkApiAntiga(_vm.fastTutorialVisualiza.url),"type":"audio/mpeg"}}),_vm._v(" Seu navegador não suporte o player de audio. ")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fd-app-breadcrumb-item active"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Tutoriais")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fd-app-breadcrumb-item active"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"}},[_vm._v("Tutoriais")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"aluno_font_color"},[_c('span',[_vm._v("Tutoriais")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-10"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v(" Lista de Tutoriais ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',[_c('small',{staticClass:"font-weight-bold"},[_vm._v("Descrição")])]),_c('th',[_c('small',{staticClass:"font-weight-bold"},[_vm._v("Conteúdo")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}}),_vm._v("  Visualizar ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',{staticClass:"fa fa-play",attrs:{"aria-hidden":"true"}}),_vm._v("  Visualizar ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"9"}},[_vm._v(" Nenhum Tutorial encontrado ")])])
}]

export { render, staticRenderFns }