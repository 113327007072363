<template>
  <div>
    <header class="fd-app-mode-ead-header">
      <div
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="
                  $router.push('/plataforma/' + $route.params.id_plataforma)
                "
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a
              >
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="
                  $router.push(
                    '/plataforma/' + $route.params.id_plataforma + '/aluno'
                  )
                "
                >Modo aluno</a
              >
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Tutoriais</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="
                  $router.push(
                    '/plataforma/' + $route.params.id_plataforma + '/aluno'
                  )
                "
                >Menu principal</a
              >
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a class="aluno_font_color" href="#">Tutoriais</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component :is="HomeInternoAlunoMenuLateral" v-if="!isMobile()" />
            </transition>
            <div
              :class="
                isMobile()
                  ? 'col-sm-12 col-md-12 col-lg-12'
                  : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'
              "
            >
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section
                            class="fd-app-welcome text-left fast-plataforma-iuea-hide"
                          >
                            <h1 class="aluno_font_color">
                              <span>Tutoriais</span>
                            </h1>
                            <div
                              class="fd-welcome-form-effect fast-plataforma-iuea-hide"
                            >
                              <img
                                :src="
                                  require('@/assets/images/form-effect-01.png')
                                "
                                class="mt-2"
                              />
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section>
                            <!-- Conteúdo principal -->
                            <div class="card-body">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <!-- começa grid -->

                                  <div class="card shadow mb-4">
                                    <div class="card-header py-3">
                                      <div class="row">
                                        <div
                                          class="col-sm-12 col-md-12 col-lg-10"
                                        >
                                          <h6
                                            class="m-0 font-weight-bold text-primary"
                                          >
                                            Lista de Tutoriais
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div
                                          class="col-12 table-responsive mt-2"
                                        >
                                          <table class="table table-sm">
                                            <thead class="thead-dark">
                                              <tr class="text-center">
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                    >Descrição</small
                                                  >
                                                </th>
                                                <th>
                                                  <small
                                                    class="font-weight-bold"
                                                    >Conteúdo</small
                                                  >
                                                </th>
                                              </tr>
                                            </thead>

                                            <tbody
                                              v-if="fastTutorialTotal.length"
                                            >
                                              <tr
                                                v-for="Tutorial in fastTutorialTotal"
                                                :key="Tutorial.id_tutorial"
                                              >
                                                <td
                                                  class="align-middle text-center"
                                                >
                                                  {{ Tutorial.descricao }}
                                                </td>

                                                <td class="text-center">
                                                  <div
                                                    v-if="Tutorial.tipo == 'A'"
                                                  >
                                                    <a
                                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                                      :href="ajustaLinkApiAntiga(Tutorial.url)"
                                                      target="_blank"
                                                      download
                                                    >
                                                      <small>
                                                        <i
                                                          class="fa fa-eye"
                                                          aria-hidden="true"
                                                        />
                                                        &nbsp;Visualizar
                                                      </small>
                                                    </a>
                                                  </div>
                                                  <div
                                                    v-else-if="
                                                      Tutorial.tipo == 'V'
                                                    "
                                                  >
                                                    <button
                                                      class="btn btn-primary btn-sm mt-2 ml-2"
                                                      @click="
                                                        visualizarAtividade(
                                                          Tutorial
                                                        )
                                                      "
                                                    >
                                                      <small>
                                                        <i
                                                          class="fa fa-play"
                                                          aria-hidden="true"
                                                        />
                                                        &nbsp;Visualizar
                                                      </small>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                            <tbody v-else>
                                              <tr>
                                                <td
                                                  colspan="9"
                                                  class="text-center"
                                                >
                                                  Nenhum Tutorial encontrado
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <!-- <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="10"
                  :items="fastOcorrenciaFiltro"
                  @changePage="fastOcorrenciaPagination = $event"
                />
              </div> -->
                                      </div>
                                    </div>
                                  </div>

                                  <!--termina grid -->
                                </div>
                              </div>
                            </div>
                            <!-- /Conteúdo principal -->
                          </section>

                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="
                                '/plataforma/' +
                                $route.params.id_plataforma +
                                '/aluno'
                              "
                              @click.prevent="
                                $router.push(
                                  '/plataforma/' +
                                    $route.params.id_plataforma +
                                    '/aluno'
                                )
                              "
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- modal -->
    <modal
      name="modalVisualizarAtividade"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Tutorial - {{ fastTutorialVisualiza.descricao }}</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalVisualizarAtividade')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div v-if="fastTutorialVisualiza.tipo == 'V'" class="col-12">
              <iframe
                :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)"
                width="100%"
                height="315"
                frameborder="0"
              />
            </div>
            <div v-else class="col-12">
              <audio controls class="d-block m-auto">
                <source :src="ajustaLinkApiAntiga(fastTutorialVisualiza.url)" type="audio/mpeg" />
                Seu navegador não suporte o player de audio.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import muralAvisos from "../components/MuralAvisos";
import Pagination from "../components/Pagination";

export default {
  // Nome do componente
  name: "HomeInternoAlunoTutoriais",
  // Componentes
  components: {
    muralAvisos,
    Pagination,
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTemplate: settings.fastTemplate,
      fastTutorialTotal: [],
      fastTutorialVisualiza: {},
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, "aluno")
        .then(() => {
          // Iniciar aqui
          this.getTutorial(this.$route.params.id_plataforma);
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
    async getTutorial(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_plataforma_tutorial/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((obj) => {
          if (obj.length) {
            this.fastTutorialTotal = obj;
          } else {
            this.fastTutorialTotal = [];
          }
          this.$store.state.fastCarregando = false;
          this.fastTutorialLoading = false;
        })
        .catch((e) => {
          this.exibeToasty("Erro ao buscar Tutorial", "error");
          this.$store.state.fastCarregando = false;
          this.fastTutorialficaLoading = false;
        });
    },

    visualizarAtividade(Tutorial) {
        
      this.fastTutorialVisualiza = Tutorial;
      if (Tutorial.tipo == "V") {
        if (Tutorial.url.indexOf("https://vimeo.com/") != "-1") {
          Tutorial.url =
            "https://player.vimeo.com/video/" + Tutorial.url.split("/").pop();
        }
      }
      this.showModal("modalVisualizarAtividade");
    },
  },
};
</script>

<style scope>
</style>
